import React, { useState } from "react";

const host = "http://127.0.0.1:8080/api";

export default function Login() {
  const [str, setStr] = useState(Date.now());

  const login = () => {
    const requestBody = {
      account: "账号错误",
      password: "账号错误",
    };

    fetch(`${host}/login`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setStr(data.msg + Date.now()))
      .catch((error) => console.error(error));
  };

  const login2 = () => {
    const requestBody = {
      account: "账号正确",
      password: "账号正确",
    };

    fetch(`${host}/login`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setStr(data.msg + Date.now() + "构建表单提交。。。。。");

        const form = document.createElement("form");
        form.action = `${host}/redirect_1`;
        form.enctype = "application/json";
        form.method = "post";
        const account = document.createElement("input");
        account.name = "account";
        account.value = "https://demo.xiaolvshu.fun/jump";
        form.appendChild(account);

        document.getElementById("con").appendChild(form);
        form.submit();
      })
      .catch((error) => console.error(error));
  };

  const login3 = () => {
    const requestBody = {
      account: "账号正确",
      password: "账号正确",
    };

    fetch(`${host}/login`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setStr(data.msg + Date.now() + "构建表单提交。。。。。");

        const form = document.createElement("form");
        form.action = `${host}/redirect_1`;
        form.enctype = "application/json";
        form.method = "post";
        const account = document.createElement("input");
        account.name = "account";
        account.value = "https://www.baidu.com?code=xxxxx";
        form.appendChild(account);

        document.getElementById("con").appendChild(form);
        form.submit();
      })
      .catch((error) => console.error(error));
  };
  return (
    <div>
      <button onClick={() => login()}>账号错误</button>
      <button onClick={() => login2()}>账号正确|”https://jump“</button>
      {/* <button onClick={() => login3()}>账号正确|”https://www.baidu.com“</button> */}
      {str}

      <div id="con"></div>
    </div>
  );
}

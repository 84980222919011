import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./page/login";
import Jump from "./page/jump";

function App() {
  return (
    <div className="App" style={{ height: "100vh" }}>
      <Router>
        <Routes>
          <Route path="/login" Component={Login}></Route>
          <Route path="/jump" Component={Jump}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React from "react";
const host = "http://127.0.0.1:8080/api";
export default function jump() {
  const login3 = () => {
    const form = document.createElement("form");
    form.action = `${host}/redirect_2`;
    form.enctype = "application/json";
    form.method = "post";
    const account = document.createElement("input");
    account.name = "account";
    account.value = "https://www.baidu.com?code=xxxxx";
    form.appendChild(account);

    document.getElementById("con").appendChild(form);
    form.submit();
  };
  return (
    <div>
      jump
      <button onClick={login3}>手动触发跳转 b用户页面 </button>
      <div id="con"></div>
    </div>
  );
}
